import React from 'react'
import { useExportSummaryMutation } from '../../queries/missions'
import { Button, Columns, Dropdown, Element, Icon } from 'react-bulma-components'
import { ExportSummaryParams } from '../../api/missions'
import { DownloadIcon } from '@heroicons/react/outline'
import useFileDownload from '../../hooks/use-file-download'
import useXlsxDownload from '../../hooks/use-xlsx-download'
import Modal, { useModal } from '../modal'
import { Field, Form, Formik } from 'formik'
import InputField from '../form/fields/input'
import SelectField from '../form/fields/select'
import { eachWeekOfInterval, endOfWeek, startOfWeek } from 'date-fns'
import { fr } from 'date-fns/locale'
import { dateToDateInputValue, formatCompleteDate } from '../../utils/date'

interface ExportButtonProps {
  filters: Omit<ExportSummaryParams, 'type'>
}

const ExportButton: React.FC<ExportButtonProps> = ({ filters }) => {
  const exportSummaryMutation = useExportSummaryMutation()
  const modal = useModal()

  const [exportType, setExportType] = React.useState<'xls' | 'csv' | 'custom'>()

  React.useEffect(() => {
    exportType &&
      exportType !== 'custom' &&
      exportSummaryMutation.mutate({ ...filters, type: exportType })
  }, [exportType])

  //hook to download csv query result (if export type === csv)
  useFileDownload(
    `teamtim-export.csv`,
    exportSummaryMutation.variables?.type === 'csv' ? exportSummaryMutation.data?.data : undefined,
  )

  //hook to download xls query result (if export type === xls)
  useXlsxDownload(
    `teamtim-export.xlsx`,
    exportSummaryMutation.variables?.type === 'xls' ? exportSummaryMutation.data?.data : undefined,
  )

  React.useEffect(() => {
    if (exportSummaryMutation.status === 'success') {
      setExportType(undefined)
      exportSummaryMutation.reset()
    }
  }, [exportSummaryMutation.status])

  return (
    <>
      <Modal actions={modal} title="Export personnalisé">
        <Formik
          initialValues={{
            from: '',
            to: '',
            type: '',
          }}
          onSubmit={values => {
            exportSummaryMutation.mutate({
              ...filters,
              type: values.type,
              start: values.from,
              end: values.to,
            })
          }}
          validate={values => {
            const errors: any = {}
            if (!values.type) errors.type = 'Champ requis'
            if (!values.from) errors.from = 'Champ requis'
            if (!values.to) errors.to = 'Champ requis'

            return errors
          }}
        >
          {props => {
            const start =
              props.values.from && startOfWeek(new Date(props.values.from), { locale: fr })
            const end = props.values.to && endOfWeek(new Date(props.values.to), { locale: fr })

            const weeksCount =
              start && end && eachWeekOfInterval({ start, end }, { locale: fr }).length

            return (
              <Form>
                <Columns>
                  <Columns.Column>
                    <Field
                      label="Du"
                      name="from"
                      type="date"
                      component={InputField}
                      max={props.values.to && dateToDateInputValue(new Date(props.values.to))}
                      help="Chaque semaine est exportée totalement"
                    />
                  </Columns.Column>
                  <Columns.Column>
                    <Field
                      label="Au"
                      name="to"
                      type="date"
                      min={props.values.from && dateToDateInputValue(new Date(props.values.from))}
                      component={InputField}
                    />
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <Field
                      label="Type de fichier"
                      name="type"
                      component={SelectField}
                      options={[
                        { value: 'xls', label: 'Excel' },
                        { value: 'csv', label: 'CSV' },
                      ]}
                    />
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    {start && end && (
                      <Element mb={4}>
                        Export de {weeksCount} semaines
                        <br />
                        Du {formatCompleteDate(start)} au {formatCompleteDate(end)}
                      </Element>
                    )}
                    <Button color="primary">Exporter</Button>
                    {exportSummaryMutation.isError && (
                      <Element textColor="danger">Aucune donnée à exporter</Element>
                    )}
                  </Columns.Column>
                </Columns>
              </Form>
            )
          }}
        </Formik>
      </Modal>
      <Dropdown
        closeOnSelect
        color="primary"
        icon={
          <Icon>
            <DownloadIcon />
          </Icon>
        }
        label="Export"
        onChange={(type: 'csv' | 'xls' | 'custom') => {
          if (type === 'custom') modal.setIsDisplayed(true)
          else setExportType(type)
        }}
      >
        <Dropdown.Item renderAs="a" value="csv">
          CSV
        </Dropdown.Item>
        <Dropdown.Item renderAs={'a'} value="xls">
          Excel
        </Dropdown.Item>
        <Dropdown.Item renderAs="a" value="custom">
          Choix personnalisé des dates
        </Dropdown.Item>
      </Dropdown>
    </>
  )
}

export default ExportButton
