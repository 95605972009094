import React from 'react'
import { Section } from 'react-bulma-components'
import { Column, useTable } from 'react-table'

interface TableProps<ColumnType extends object> {
  data: any
  columns: Column<ColumnType>[]
  noDataMessage?: string
  className?: string
  tableClassName?: string
}

const Table = <ColumnType extends object>({
  columns,
  data,
  noDataMessage,
  className,
  tableClassName,
}: TableProps<ColumnType>): React.ReactElement => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })
  return (
    // apply the table props
    <div className={['table-container pb-3', className].join(' ')}>
      <table
        {...getTableProps()}
        className={['table is-striped is-hoverable is-fullwidth is-narrow', tableClassName].join(
          ' ',
        )}
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps({
                        // TS 'any' workaround see react-table issue #1640
                        className: (column as any).className,
                        style: { width: undefined, ...(column as any).style },
                      })}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      //@ts-ignore
                      const isHidden = cell.column.isHidden && cell.column.isHidden(cell, data)
                      // Apply the cell prop
                      if (isHidden) return null
                      return (
                        <td
                          {...cell.getCellProps({
                            //@ts-ignore
                            rowSpan: cell.column.getRowSpan && cell.column.getRowSpan(cell, data),
                            // TS 'any' workaround see react-table issue #1640
                            className: (cell.column as any).className,
                            style: { width: undefined, ...(row as any).style },
                          })}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {!rows[0] && (
        <Section textAlign="center">{noDataMessage ?? 'Aucun élément à afficher'}</Section>
      )}
    </div>
  )
}

export default Table
