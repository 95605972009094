import React, { useMemo } from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { localDate } from '../../utils/date'
import { AppSubscriptionPeriod } from '../../api/app-subscription-periods'
import { Element, Heading } from 'react-bulma-components'

interface AppSubscriptionPeriodDailyDetailsProps {
  period: AppSubscriptionPeriod
}

type DailyMetricsWithTiers = AppSubscriptionPeriod['metrics']['dailyMetrics'][number] & {
  [key: string]: any // Pour permettre l'ajout dynamique des tiers
}

/**
 * Composant affichant les métriques journalières d'une période d'abonnement
 */
const AppSubscriptionPeriodDailyDetails: React.FC<AppSubscriptionPeriodDailyDetailsProps> = ({
  period,
}) => {
  const columns = useMemo<Column<DailyMetricsWithTiers>[]>(() => {
    const baseColumns: Column<DailyMetricsWithTiers>[] = [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Périodes de travail',
        accessor: 'workPeriodsCount',
      },
    ]

    // Ajout dynamique des colonnes pour chaque tier
    period.pricing.tiers.forEach((tier, index) => {
      baseColumns.push({
        Header: `Palier ${index + 1} (${tier.workersRange.min}-${tier.workersRange.max || '∞'})`,
        accessor: `tier${index}Count`,
      })
    })

    return baseColumns
  }, [period.pricing.tiers])

  const tableData = useMemo(() => {
    // Calculer d'abord les données journalières
    const dailyData = period.metrics.dailyMetrics.map(metric => {
      const dailyPricing = period.pricing.dailyPricing.find(
        dp => dp.date.toString() === metric.date.toString(),
      )

      const tierCounts = period.pricing.tiers.reduce(
        (acc, _, index) => ({
          ...acc,
          [`tier${index}Count`]:
            dailyPricing?.workPeriodsDistribution[index]?.workPeriodsCount || 0,
        }),
        {},
      )

      return {
        ...metric,
        ...tierCounts,
        date: localDate(metric.date),
      }
    })

    // Calculer la ligne des totaux
    const totalsRow = {
      date: <Element renderAs="strong">Total</Element>, // Date fictive pour le tri
      workPeriodsCount: <Element renderAs="strong">{period.metrics.workPeriodsCount}</Element>,
      ...period.pricing.tiers.reduce(
        (acc, _, index) => ({
          ...acc,
          [`tier${index}Count`]: (
            <Element renderAs="strong">
              {dailyData.reduce(
                //@ts-ignore
                (sum, day) => sum + (day[`tier${index}Count`] || 0),
                0,
              )}
            </Element>
          ),
        }),
        {},
      ),
    }

    // Retourner le tableau avec la ligne des totaux en première position
    return [totalsRow, ...dailyData]
  }, [period])

  return (
    <>
      <Heading size={6} className="mb-4">
        Détails journaliers
      </Heading>
      <Table
        columns={columns}
        data={tableData}
        className="is-bordered is-striped is-narrow"
        noDataMessage="Aucune donnée journalière disponible"
      />
    </>
  )
}

export default AppSubscriptionPeriodDailyDetails
