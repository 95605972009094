import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './interim-agency-settings-wrapper'
import {
  useAppSubscriptionPeriodByPeriodQuery,
  useAppSubscriptionPeriodTotalsQuery,
  useCreateAppSubscriptionPeriodMutationFromTotal,
} from '../../queries/app-subscription-periods'
import useUrlId from '../../hooks/use-url-id'
import { formatDate, formatUtcDate } from '../../utils/date'
import { isWithinInterval } from 'date-fns'
import { Employer } from '../../api/organizations'
import { Columns, Element, Heading, Button, Box } from 'react-bulma-components'
import AppSubscriptionPeriodTable from '../../components/app-subscription-periods/app-subscription-period-table'
import AppSubscriptionPeriodTotalsTable from '../../components/app-subscription-periods/app-subscription-period-totals-table'
import AppSubscriptionPeriodDailyDetails from '../../components/app-subscription-periods/app-subscription-period-daily-details'
import { ArrowUpIcon, ArrowDownIcon } from '../../components/icons'
import { parsePeriodString } from '../../utils/app-subscription-periods'
import { AppSubscriptionPeriodsInterimAgencyTotal } from '../../api/app-subscription-periods'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import AppSubscriptionPeriodTerminalsTable from '../../components/app-subscription-periods/app-subscription-period-terminals-table'
import Modal, { useModal } from '../../components/modal'
import Protected from '../../components/protected/protected'
import AppSubscriptionPeriodValidationForm from '../../components/app-subscription-periods/app-subscription-period-validation-form'
/**
 * Component for managing agency subscription settings
 * Receives interimAgency from outlet context and passes it to the form
 */
type AppSubscriptionPeriodProps = OutletContext

const AppSubscriptionPeriod: React.FC = () => {
  const { id, isNew } = useUrlId({ idName: 'appSubscriptionPeriodId' })
  const { interimAgency } = useOutletContext<AppSubscriptionPeriodProps>()
  const [activeEmployer, setActiveEmployer] = React.useState<Employer | null>(null)
  const [showDailyDetails, setShowDailyDetails] = React.useState(false)
  const terminalModal = useModal()
  const validationModal = useModal()

  let periodYear: number | undefined
  let periodNumber: number | undefined
  if (id && !isNew) {
    const parsedPeriod = parsePeriodString(id)
    periodYear = parsedPeriod.periodYear
    periodNumber = parsedPeriod.periodNumber
  }

  const appSubscriptionPeriodTotalsQuery = useAppSubscriptionPeriodTotalsQuery({
    interimAgencyId: interimAgency._id,
    periodNumber,
    periodYear,
  })

  const { data: period } = useAppSubscriptionPeriodByPeriodQuery(
    {
      //@ts-ignore
      periodNumber: appSubscriptionPeriodTotalsQuery.data?.period.periodNumber,
      //@ts-ignore
      periodYear: appSubscriptionPeriodTotalsQuery.data?.period.year,
      interimAgencyId: interimAgency._id,
      employerId: activeEmployer?._id || '',
    },
    { enabled: Boolean(activeEmployer) && Boolean(appSubscriptionPeriodTotalsQuery.data) },
  )

  const createAppSubscriptionPeriodsMutation = useCreateAppSubscriptionPeriodMutationFromTotal(
    appSubscriptionPeriodTotalsQuery.data as AppSubscriptionPeriodsInterimAgencyTotal,
  )

  React.useEffect(() => {
    appSubscriptionPeriodTotalsQuery.refetch()
    setActiveEmployer(null)
    terminalModal.setIsDisplayed(false)
  }, [id, isNew])

  const appSubscriptionPeriodTotal = appSubscriptionPeriodTotalsQuery.data

  const isCurrentPeriod =
    appSubscriptionPeriodTotal &&
    isWithinInterval(new Date(), {
      start: new Date(appSubscriptionPeriodTotal.period.from),
      end: new Date(appSubscriptionPeriodTotal.period.to),
    })

  const employer = period?.employer as Employer
  console.log('!!!')
  if (!appSubscriptionPeriodTotal) return <></>

  return (
    <>
      <SidebarContentTitle title="Détails de la période d'abonnement" />
      <Box>
        <Element>
          <Columns mb={1}>
            <Columns.Column size={6}>
              <strong>Ref Client:</strong> {interimAgency?.name}
            </Columns.Column>
            <Columns.Column size={6}>
              <strong>N° de Période:</strong> {appSubscriptionPeriodTotal.period.identifier}
            </Columns.Column>
          </Columns>
          <Columns mb={1}>
            <Columns.Column size={6}>
              <strong>Période:</strong> du {formatDate(appSubscriptionPeriodTotal.period?.from)} au{' '}
              {formatUtcDate(appSubscriptionPeriodTotal.period?.to.toString())}
              {isCurrentPeriod && (
                <>
                  <br />
                  <Element renderAs="i" className="text-muted">
                    Periode en cours
                  </Element>
                </>
              )}
            </Columns.Column>
            <Columns.Column size={6}>
              <div className="d-flex align-items-center gap-2">
                <strong>Status:</strong> {appSubscriptionPeriodTotal.status}
                <Protected roles={['superAdmin']}>
                  {appSubscriptionPeriodTotal.status === 'draft' && (
                    <Button
                      onClick={() => validationModal.setIsDisplayed(true)}
                      size="small"
                      ml={5}
                      pull="right"
                      color={'success'}
                      textColor="white"
                    >
                      Valider la période
                    </Button>
                  )}
                </Protected>
              </div>
            </Columns.Column>
          </Columns>
          <Columns mb={1}>
            <Columns.Column size={6}>
              <strong>N° de Facture:</strong> {appSubscriptionPeriodTotal.invoiceRef || 'N/A'}
              <Protected roles={['superAdmin']}>
                {appSubscriptionPeriodTotal.employerDetails[0]?.appSubscriptionPeriodId && (
                  <Button
                    onClick={() => validationModal.setIsDisplayed(true)}
                    size="small"
                    ml={5}
                    pull="right"
                    color={'ghost'}
                  >
                    Modifier
                  </Button>
                )}
              </Protected>
            </Columns.Column>
            <Columns.Column size={6}>
              <strong>Commentaire:</strong> {appSubscriptionPeriodTotal.comment || 'N/A'}
            </Columns.Column>
          </Columns>
          <AppSubscriptionPeriodTotalsTable
            totals={appSubscriptionPeriodTotal}
            onEmployerClick={employer => setActiveEmployer(employer)}
          />
          {activeEmployer && period && (
            <>
              <Element className="is-flex is-justify-content-space-between is-align-items-center mb-4">
                <Heading size={5}>Détails coûts {activeEmployer.name}</Heading>
                <Button remove onClick={() => setActiveEmployer(null)} aria-label="close" />
              </Element>
              <Columns mb={1}>
                <Columns.Column size={6}>
                  <strong>Ref Client:</strong> {interimAgency?.name}
                </Columns.Column>
                <Columns.Column size={6}>
                  <strong>N° de Période:</strong> {period.identifier || 'Période en cours'}
                </Columns.Column>
              </Columns>
              <Columns mb={1}>
                <Columns.Column size={6}>
                  <strong>Entreprise Utilisatrice:</strong> {employer?.name}
                </Columns.Column>
                <Columns.Column size={6}>
                  <strong>Période:</strong> du {formatDate(period.periodStart)} au{' '}
                  {formatUtcDate(period.periodEnd.toString())}
                </Columns.Column>
              </Columns>
              <AppSubscriptionPeriodTable
                period={period}
                onTerminalDetailsClick={() => terminalModal.setIsDisplayed(true)}
              />

              <Element className="is-flex is-justify-content-flex-end" style={{ marginTop: -30 }}>
                <Button
                  onClick={() => setShowDailyDetails(!showDailyDetails)}
                  size="small"
                  color="ghost"
                  renderAs="a"
                  className="mt-n4"
                >
                  Détails journaliers -&nbsp;
                  {showDailyDetails ? (
                    <>
                      Masquer <ArrowUpIcon className="ml-1" width={16} height={16} />
                    </>
                  ) : (
                    <>
                      Afficher <ArrowDownIcon className="ml-1" width={16} height={16} />
                    </>
                  )}
                </Button>
              </Element>

              {showDailyDetails && (
                <Element mt={2}>
                  <AppSubscriptionPeriodDailyDetails period={period} />
                </Element>
              )}
              <Modal actions={terminalModal} title="Détails des terminaux" maxWidth={1000}>
                <AppSubscriptionPeriodTerminalsTable
                  weeklyTerminalFees={period.fees.weeklyTerminalFees}
                  totalTerminalFees={period.fees.totalTerminalFees}
                />
              </Modal>
            </>
          )}
        </Element>
      </Box>
      <Modal actions={validationModal} title="Validation de la période">
        <AppSubscriptionPeriodValidationForm
          periodTotal={appSubscriptionPeriodTotal}
          onSuccess={() => validationModal.setIsDisplayed(false)}
        />
      </Modal>
    </>
  )
}

export default AppSubscriptionPeriod
